/* eslint-disable */



'use strict'

function init(widgetId, widgetBlockClassName) {
  var widgetInitInterval = setInterval(function() {
    if ((typeof widgetBlockClassName === 'undefined') && (typeof widgetId === 'undefined')) {
      clearInterval(widgetInitInterval);
      return;
    }

    var wrapperIframeNode = document.querySelector('.' + widgetBlockClassName + ':not(.dls-loaded)');

    if (!wrapperIframeNode) return;

    clearInterval(widgetInitInterval);

    var siteUrl = "https://deals.innocode.no/",
      viewPortStartingPoint = window.innerHeight * 2,
      widgetIframeNode,
      widgetIframeInnerWrapperNode,
      globalIDAnimationFrame,
      wrapperIframeNodePosition,
      scrollTimeout,
      wrapperIframeNodeHeight,
      wrapperIframeNodeWidth,
      isHeightConfigured = false,
      visibilityChangeFirstInit = false;

    function generateDebugParam() {
      var debug = window.location.href.match(/debug=([^&]*)/i);

      if (!debug) return '';

      return '&debug=' + debug[1];
    }

    function visibilityChangeGenerator(onChangeCallback) {
      var hidden = "hidden";

      if (hidden in document) {
        document.addEventListener("visibilitychange", onchange, false);
      } else if ((hidden = "mozHidden") in document) {
        document.addEventListener("mozvisibilitychange", onchange, false);
      } else if ((hidden = "webkitHidden") in document) {
        document.addEventListener("webkitvisibilitychange", onchange, false);
      } else if ((hidden = "msHidden") in document) {
        document.addEventListener("msvisibilitychange", onchange, false);
      } else if ("onfocusin" in document) {
        document.onfocusin = document.onfocusout = onchange;
      } else {
        window.onpageshow = window.onpagehide = window.onfocus = window.onblur = onchange
      }

      function onchange (evt) {
        var v = "visible", h = "hidden",
            evtMap = {
              focus:v, focusin:v, pageshow:v, blur:h, focusout:h, pagehide:h
            };
        var visibleStatus;

        evt = evt || window.event;
        if (evt.type in evtMap) {
          visibleStatus = evtMap[evt.type];
        } else {
          visibleStatus = this[hidden] ? "hidden" : "visible";
        }

        onChangeCallback(visibleStatus);
      }

      // set the initial state (but only if browser supports the Page Visibility API)
      if( document[hidden] !== undefined ) {
        onchange({type: document[hidden] ? "blur" : "focus"});
      }
    }

    widgetIframeNode = document.createElement('iframe');
    widgetIframeNode.id = widgetBlockClassName + '_deals_iframe_' + widgetId;
    widgetIframeNode.title = "Local Offers";
    widgetIframeNode.src = siteUrl + 'api/public/v1/widgets/' + widgetId + '?v=3' + generateDebugParam();
    widgetIframeNode.style.left = '0';
    widgetIframeNode.style.top = '0';
    widgetIframeNode.style.width = '100%';
    widgetIframeNode.style.height = '100%';
    widgetIframeNode.style.position = 'absolute';
    widgetIframeNode.style.border = 'none';

    wrapperIframeNodeWidth = wrapperIframeNode.style.width;
    wrapperIframeNodeHeight = wrapperIframeNode.style.height;

    // Set default iframe width to 100%.
    if (wrapperIframeNodeWidth.length < 0) {
      wrapperIframeNode.style.width = '100%';
    }

    widgetIframeInnerWrapperNode = document.createElement('div');
    widgetIframeInnerWrapperNode.style.position = 'relative';
    wrapperIframeNode.appendChild(widgetIframeInnerWrapperNode);

    if (wrapperIframeNodeHeight.length > 0) {
      isHeightConfigured = true;
      widgetIframeNode.style.height = wrapperIframeNodeHeight;
      widgetIframeInnerWrapperNode.style.height = wrapperIframeNodeHeight;
    }

    wrapperIframeNode.classList.add('dls-loaded');

    wrapperIframeNodePosition = wrapperIframeNode.offsetTop;

    widgetIframeNode.onload = function(e) {
      if (widgetIframeNode.contentWindow) {
        widgetIframeNode.contentWindow.postMessage({
          from: 'dealsParentWindow',
          originUrl: window.location.href,
          originWindowWidth: window.innerWidth,
        }, '*');

        widgetIframeNode.contentWindow.postMessage({
          from: 'dealsParentWindow',
          isIFrameHeightSet: isHeightConfigured,
        }, '*');
      }

      // Whenever widget visibility in the viewport changes inform about that widget.
      window.addEventListener('scroll', inViewportCheck, false);

      // Inform widget about its initial visibility in the viewport.
      visibilityChangeGenerator(function(visibleStatus) {
        if (visibilityChangeFirstInit && widgetIframeNode.contentWindow) {
          widgetIframeNode.contentWindow.postMessage({
            from: 'dealsParentWindow',
            inViewport: iframeViewportCheck(),
          }, '*');
        }
        visibilityChangeFirstInit = true;
      });

      // When widget is ready, check its visibility once more.
      window.addEventListener('message', function(event) {
        if(event.data && event.data.from === 'dealsWidget' && event.data.widgetReady) {
          viewportCheckAndSendMessageToIframe();
        }
      }, false)
    }

    var widgetIframeNodeExists = function() {
      return document.getElementById(widgetIframeNode.id);
    }

    var iframeInViewport = false;

    function iframeViewportCheck() {
      var rect = widgetIframeNode.getBoundingClientRect();
      var viewportThreshold = (widgetIframeNode.offsetHeight / 10) * 5;

      return (rect.top >= -viewportThreshold) && (rect.bottom <= window.innerHeight + viewportThreshold);
    }

    function viewportCheckAndSendMessageToIframe() {
      var inViewport = iframeViewportCheck();

      if (inViewport !== iframeInViewport && widgetIframeNode.contentWindow) {
        widgetIframeNode.contentWindow.postMessage({
          from: 'dealsParentWindow',
          inViewport: inViewport,
        }, '*');
      }

      iframeInViewport = inViewport;
    }

    function inViewportCheck() {
      if (globalIDAnimationFrame) {
        cancelAnimationFrame(globalIDAnimationFrame);
        globalIDAnimationFrame = null;
      }

      globalIDAnimationFrame = requestAnimationFrame(viewportCheckAndSendMessageToIframe);
    }

    function initIframe() {      
      if (globalIDAnimationFrame) {
        cancelAnimationFrame(globalIDAnimationFrame);
        globalIDAnimationFrame = null;
      }

      globalIDAnimationFrame = requestAnimationFrame(function() {
        // TODO: wrapperIframeNodePosition is possibly used not properly here
        if (!widgetIframeNodeExists() && (wrapperIframeNodePosition <= (window.pageYOffset + viewPortStartingPoint))) {
          widgetIframeInnerWrapperNode.appendChild(widgetIframeNode);
          window.removeEventListener('scroll', initIframe);
        }
      }, false);
    }

    // Insert iFrame to the DOM when user scrolls to it.
    window.addEventListener('scroll', initIframe, false);
    initIframe();
    
    function performAutoheight(dimensions) {
      var width = dimensions.width || 100;
      var height = dimensions.height || 50;

      var ratio = height / width;
      widgetIframeInnerWrapperNode.style.paddingBottom = ratio * 100 + '%';    
    }

    if (!isHeightConfigured) {
      window.addEventListener('message', function(event) {
        if (event.data && event.data.from === 'dealsWidget' && event.data.widgetDimensions) {
          performAutoheight(event.data.widgetDimensions);
        }
      }, false)
    }
  }, 1000)
}

var dealsWidget = {
  init: function(widgetId, widgetBlockClassName) {
    widgetId && init(widgetId, widgetBlockClassName)
  }
}

window.dealsWidget && window.dealsWidget.q && window.dealsWidget.q.forEach(function(args){
  dealsWidget[args[0]] && dealsWidget[args[0]].apply(dealsWidget, [].slice.call(args, 1));
})
